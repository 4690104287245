import {useAppStore} from "~/store/appStore";
const appStore = useAppStore()

export default defineNuxtRouteMiddleware(async (to, from) => {

    const requestEstablishment = async () => {
        const establishment = await $fetch("/api/getEstablishment/", {
            method: "GET"
        })
        return establishment
    }

    const requestTheme = async (themeId: any) => {
        const theme = await $fetch(`/api/getTheme/${themeId}`, {
            method: "GET"
        })
        return theme
    }

    const applyTheme = (theme: any) => {
        appStore.currentEtablissement.theme.light.mainColor = theme.lightPrimaryColor1
        appStore.currentEtablissement.theme.light.secondaryColor = theme.lightSecondaryColor1
        appStore.currentEtablissement.theme.light.terciaryColor = theme.lightThirdColor1
        appStore.currentEtablissement.theme.light.neutral23 = theme.lightNeutralColor1
        appStore.currentEtablissement.theme.light.shades76 = theme.lightPrimaryColor3
        appStore.currentEtablissement.theme.light.neutral45 = theme.lightNeutralColor2
        appStore.currentEtablissement.theme.light.neutral71 = theme.lightNeutralColor3
        appStore.currentEtablissement.theme.light.neutral77 = theme.lightNeutralColor4
        appStore.currentEtablissement.theme.light.shades94 = theme.lightPrimaryColor4
        appStore.currentEtablissement.theme.light.borderBtnWhite = theme.lightBackColor3
        appStore.currentEtablissement.theme.light.btnSearch = theme.lightPrimaryColor1
        appStore.currentEtablissement.theme.light.btnLinear = theme.lightPrimaryColor1
        appStore.currentEtablissement.theme.light.background97 = theme.lightBackColor1
    }

    console.log("Proceed to request Establishment & Theme of the site")
    // Site actuel
    const establishment = await requestEstablishment()

    // Prix affiché HT ou TTC
    appStore.currentEtablissement.htSite = establishment.htSite

    // isCheckStockBeforeOrder
    appStore.currentEtablissement.isCheckStockBeforeOrder = establishment.isCheckStockBeforeOrder
    
    // Theme utilisé par le site
    const themeId = establishment.themeId
    const theme = await requestTheme(themeId)
    // Apply theme
    applyTheme(theme)
});
